import { useState } from "react";

import {
  AppMenu as FoundationAppMenu,
  Icon,
  AppMenuItem,
  useAppMenuContext,
  useResponsive
} from "@introist/react-foundation/v2";
import { useNavigate } from "react-router";
import { OrganisationLogo } from "components/atoms";
import { useIdentity } from "IdentityProvider";
import { useFeatureFlag } from "hooks";
import { useQueryClient } from "@tanstack/react-query";
import { logout } from "services/firebase";
import { useWorkspaces } from "./hooks/useWorkspaces";
import { SETTINGS_APP_MENU_ITEMS, buildSubRoute, appRoutes } from "AppRoutes";
import { SupportDrawer } from "./components/SupportDrawer";
import { DATA_SOURCE_ROUTES } from "../../../modules/datasources";
import { HOLIDAY_CALENDAR_ROUTES } from "../../../modules/holiday-calendar";
import { DATASET_ROUTES } from "../../../modules/datasets";
import { WORKFLOWS_ROUTES } from "../../../modules/flows";
import { EMPLOYEE_ROUTES } from "../../../modules/employees";
import FeatherIcon from "feather-icons-react";
import { TRIGGER_ROUTES } from "../../../modules/triggers";
import { BATCHES_ROUTES } from "../../../modules/batches";
import { NUDGES_ROUTES } from "../../../modules/nudges";
import { FORMS_ROUTES } from "../../../modules/forms";

export const AppMenu = () => {
  const queryClient = useQueryClient();

  const { workspaces } = useWorkspaces();

  const { user } = useIdentity();

  const { collapsed, setCollapsed, toggleMenuCollapsed } = useAppMenuContext();
  const { createWorkspace, selectWorkspace } = useWorkspaces();

  const hasLegacy = useFeatureFlag("legacy").isEnabled;
  const hasDatasets = useFeatureFlag("datasets").isEnabled;

  const hasOnboardingModule = useFeatureFlag("module-onboarding").isEnabled;
  const hasBatches = useFeatureFlag("batches").isEnabled;
  const hasForms = useFeatureFlag("forms").isEnabled;

  const { isMobileLayout } = useResponsive();

  const [supportDrawerOpen, setSupportDrawerOpen] = useState(false);

  const navigate = useNavigate();
  const handleItemClick = (to: string) => (e: React.MouseEvent) => {
    if (e.metaKey || e.ctrlKey) window.open(to, "_blank");
    else navigate(to);
    isMobileLayout && setCollapsed(true);
  };

  const getItems = (): AppMenuItem[] => {
    let items: AppMenuItem[] = [];

    if (hasOnboardingModule) {
      items.push({
        title: "Onboarding",
        path: appRoutes.onboardingRoot.path,
        icon: <Icon name="personAdd" />,
        divider: true,
        onClick: handleItemClick(appRoutes.onboardingRoot.path)
      });
    }

    items.push({
      title: "Employees",
      path: appRoutes.employees.path,
      icon: appRoutes.employees.icon,
      onClick: handleItemClick(appRoutes.employees.path)
    });

    items.push({
      title: "Automations",
      path: appRoutes.automations.path,
      icon: <Icon name="condition" />,
      onClick: handleItemClick(appRoutes.automations.path),
      divider: !hasBatches
    });

    if (hasBatches) {
      items.push({
        title: "Batches",
        path: BATCHES_ROUTES.batches.path,
        icon: BATCHES_ROUTES.batches.icon,
        onClick: handleItemClick(BATCHES_ROUTES.batches.path)
      });
    }

    items.push({
      title: "Nudges",
      path: NUDGES_ROUTES.nudges.path,
      icon: NUDGES_ROUTES.nudges.icon,
      onClick: handleItemClick(NUDGES_ROUTES.nudges.path),
      divider: true
    });
    items.push({
      title: TRIGGER_ROUTES.triggers.title || "Triggers",
      icon: TRIGGER_ROUTES.triggers.icon,
      path: TRIGGER_ROUTES.triggers.path,
      onClick: handleItemClick(TRIGGER_ROUTES.triggers.path)
    });
    items.push({
      title: WORKFLOWS_ROUTES.flows.title || "Workflows",
      icon: WORKFLOWS_ROUTES.flows.icon,
      path: WORKFLOWS_ROUTES.flows.path,
      onClick: handleItemClick(WORKFLOWS_ROUTES.flows.path),
      divider: true
    });

    items.push({
      title: appRoutes.events.title!,
      icon: appRoutes.events.icon,
      path: appRoutes.events.path,
      onClick: handleItemClick(appRoutes.events.path),
      divider: !hasLegacy
    });

    if (hasForms) {
      items.push({
        title: "Forms",
        icon: FORMS_ROUTES.forms.icon,
        path: FORMS_ROUTES.forms.path,
        onClick: handleItemClick(FORMS_ROUTES.forms.path)
      });
    }

    if (hasLegacy) {
      items.push({
        title: appRoutes.materials.title!,
        path: appRoutes.materials.path,
        icon: appRoutes.materials.icon,
        onClick: handleItemClick(appRoutes.materials.path),
        divider: true
      });
    }

    const subItems = [
      {
        title: "Employee Fields",
        path: EMPLOYEE_ROUTES.employeeFields.path,
        onClick: handleItemClick(EMPLOYEE_ROUTES.employeeFields.path)
      },
      {
        title: "Data Sources",
        path: DATA_SOURCE_ROUTES.datasources.path,
        onClick: handleItemClick(DATA_SOURCE_ROUTES.datasources.path)
      }
    ];

    if (hasDatasets) {
      subItems.push({
        title: "Datasets",
        path: DATASET_ROUTES.datasets.path,
        onClick: handleItemClick(DATASET_ROUTES.datasets.path)
      });
    }

    items.push({
      title: "Data",
      icon: <FeatherIcon icon="database" size="17px" />,
      closable: true,
      subItems
    });

    const settingsSubItems =
      user.workspace?.plan === "basic"
        ? SETTINGS_APP_MENU_ITEMS.subItems?.filter(item =>
            ["workspace", "accounts", "sso", "ai"].includes(item.path!)
          )
        : SETTINGS_APP_MENU_ITEMS.subItems;

    const settingsSub = [
      ...(settingsSubItems ?? []).map(settingsRoute => {
        return {
          ...settingsRoute,
          path: buildSubRoute("settings", settingsRoute.path!),
          onClick: handleItemClick(buildSubRoute("settings", settingsRoute.path!))
        };
      }),
      {
        key: "holidays",
        title: "Holidays",
        path: HOLIDAY_CALENDAR_ROUTES.holidays.path,
        onClick: handleItemClick(HOLIDAY_CALENDAR_ROUTES.holidays.path)
      }
    ];

    items.push({
      title: SETTINGS_APP_MENU_ITEMS.title,
      icon: SETTINGS_APP_MENU_ITEMS.icon,
      closable: true,
      subItems: settingsSub,
      divider: true
    });

    items.push({
      title: "Notifications",
      path: appRoutes.notifications.path,
      icon: <Icon name="bell" />,
      onClick: handleItemClick(appRoutes.notifications.path)
    });

    return items;
  };

  return (
    <>
      <FoundationAppMenu
        collapsed={collapsed}
        user={user}
        onToggleCollapse={toggleMenuCollapsed}
        onLogout={() => {
          logout()
            .then(() => queryClient.invalidateQueries())
            .then(() => navigate(appRoutes.login.path));
        }}
        items={getItems()}
        bottomItems={[
          {
            title: "Help & Support",
            icon: <Icon name="bulb" />,
            onClick: () => setSupportDrawerOpen(true)
          }
        ]}
        organisation={{
          name: user.workspace?.name ?? "",
          logo: <OrganisationLogo workspace={user?.workspace} />
        }}
        workspaces={workspaces}
        createWorkspace={name => createWorkspace && createWorkspace({ name })}
        switchWorkspace={selectWorkspace}
      />

      <SupportDrawer
        open={supportDrawerOpen}
        userEmail={user.email}
        onClose={() => setSupportDrawerOpen(false)}
      />
    </>
  );
};
