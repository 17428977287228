import { api, RouterInput, RouterOutput } from "../../services/rpc/RpcProvider";
import { useToast } from "@introist/react-foundation/v2";
import { useCallback } from "react";

export type Form = RouterOutput["forms"]["v2"]["find"];
export type FormCreate = RouterInput["forms"]["v2"]["create"];
export type FormUpdate = RouterInput["forms"]["v2"]["update"];

export const useForms = () => {
  const { data: forms } = api.forms.v2.list.useQuery({});

  return { forms };
};

export const useForm = (id: string) => {
  const { data: form } = api.forms.v2.find.useQuery({ id });
  return { form };
};

export const useFormActions = () => {
  const toast = useToast();
  const apiUtils = api.useContext();

  const listOp = apiUtils.forms.v2.list;
  const findOp = apiUtils.forms.v2.find;

  const createOp = api.forms.v2.create.useMutation();
  const updateOp = api.forms.v2.update.useMutation();
  const archiveOp = api.forms.v2.archive.useMutation();

  const create = useCallback(
    async (req: FormCreate) => {
      return createOp
        .mutateAsync(req)
        .then(async created => {
          await listOp.refetch();
          return created;
        })
        .catch(e => {
          toast.error("Failed to create Form");
          throw e;
        });
    },
    [createOp, listOp, toast]
  );

  const update = useCallback(
    async (req: FormUpdate) => {
      return updateOp
        .mutateAsync(req)
        .then(async updated => {
          await listOp.refetch();
          await findOp.refetch({ id: req.id });
          return updated;
        })
        .catch(e => {
          toast.error("Failed to create Form");
          throw e;
        });
    },
    [updateOp, listOp, findOp, toast]
  );

  const archive = useCallback(
    async (id: string) => {
      await archiveOp
        .mutateAsync({ id })
        .then(async () => {
          await listOp.refetch();
          toast.success("Form archived");
        })
        .catch(() => {
          toast.error("Failed to archive Form");
        });
    },
    [archiveOp, toast, listOp]
  );

  return { create, update, archive };
};
