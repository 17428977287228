import { useNavigate, useParams } from "react-router";
import { TopBarRoute } from "../../../components/templates";
import { useForm, useFormActions } from "../useForms";
import { ActionMenu, Card, H3, Stack, useActions } from "@introist/react-foundation/v2";
import { IconPickerIcon } from "../../../components/organisms";
import { useState } from "react";
import { FormForm } from "../FormForm";
import { UpsertModal } from "../../../foundation";
import { FittedTitle } from "../../../components/atoms";

export const FormRoute = () => {
  const { onConfirmAction } = useActions();
  const navigate = useNavigate();
  const { id } = useParams();

  const { form } = useForm(id!);
  const { update, archive } = useFormActions();

  const [editOpen, setEditOpen] = useState(false);

  const onArchive = onConfirmAction(
    () => archive(form!.id).then(() => navigate("/forms", { replace: true })),
    {
      title: "Confirm archival",
      description: "Are you sure to archive this form?"
    }
  );

  return (
    <TopBarRoute
      paths={[
        { key: "forms", title: "Forms", onClick: () => navigate("/forms") },
        { key: "form", title: form?.title || "" }
      ]}
    >
      <Stack vertical>
        {form && (
          <Card style={{ padding: "var(--spacing-xLarge)" }}>
            <Stack vertical gap="small">
              <Stack>
                <Stack vertical gap="xLarge">
                  <Stack>
                    <IconPickerIcon icon="form" fallbackIcon="form" />
                    <H3>{form.title}</H3>
                  </Stack>
                </Stack>
                <ActionMenu
                  style={{ marginLeft: "auto" }}
                  options={[
                    {
                      key: "edit",
                      title: "Edit",
                      startAdornmentIcon: "pencil",
                      onClick: () => setEditOpen(true)
                    },
                    {
                      key: "archive",
                      title: "Archive",
                      startAdornmentIcon: "archive",
                      onClick: onArchive
                    }
                  ]}
                />
              </Stack>
              {form.description && (
                <FittedTitle maxLength={200} style={{ marginLeft: "50px" }}>
                  {form.description}
                </FittedTitle>
              )}
            </Stack>
          </Card>
        )}
      </Stack>
      <UpsertModal
        open={editOpen}
        onClose={() => setEditOpen(false)}
        entry={form}
        onCreate={() => {
          throw new Error("Not implemented");
        }}
        onUpdate={async entry => {
          await update({ id: form!.id, update: entry });
        }}
        Form={FormForm}
        entryName="Form"
      />
    </TopBarRoute>
  );
};
